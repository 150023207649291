import { useWishlist } from "@/lib/store/wishlist";
import { NextImage, useI18n } from "@jog/react-components";
import { gtmAdpageEmail, navigate, sessionController } from "@jog/shared";
import classNames from "classnames";
import { ArrowText, Button, Input, LogoLoader } from "components";
import { getSession, signIn, useSession } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";
const LoginForm = ({ emailForCheck, isCheckout, toggleModal, goToStepOne, onLogin, }) => {
    const { $t } = useI18n();
    const [password, setPassword] = useState("");
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
    const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(true);
    const [isLoginIng, setLoginStatus] = useState(false);
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const session = useSession();
    const mergeWishlist = useWishlist.use.mergeWishlist();
    const myWishlist = useWishlist.use.myWishlist();
    const onPasswordFieldChange = (event) => {
        setPassword(event.target.value);
        setIsLoginBtnDisabled(!event.target.value || event.target.value.length <= 4);
    };
    const togglePassword = useCallback((event) => {
        event.preventDefault();
        setIsPasswordShown(!isPasswordShown);
    }, [isPasswordShown]);
    const handleLoginUser = useCallback(async (event) => {
        try {
            event.preventDefault();
            setLoginStatus(true);
            setIsPasswordInvalid(false);
            const res = await signIn("credentials", {
                redirect: false,
                email: String(emailForCheck).toLowerCase(),
                password: password,
            }).then(async (res) => {
                if (res.ok) {
                    const session = await getSession();
                    sessionController().set(session);
                }
                return res;
            });
            setLoginStatus(false);
            gtmAdpageEmail(emailForCheck.toLowerCase());
            if (res.ok) {
                await (onLogin === null || onLogin === void 0 ? void 0 : onLogin());
                const wishlistId = localStorage.getItem("wishlistId");
                if (wishlistId) {
                    mergeWishlist();
                }
                else {
                    myWishlist();
                }
            }
            if (res.error) {
                setIsPasswordInvalid(true);
            }
        }
        catch {
            setIsPasswordInvalid(false);
            setLoginStatus(false);
        }
    }, [emailForCheck, mergeWishlist, myWishlist, onLogin, password]);
    useEffect(() => {
        if (session.status === "authenticated") {
            navigate(isCheckout ? "/checkout" : "/my-account");
        }
    }, [isCheckout, session]);
    return (<div className="text-base">
            {isLoginIng && <LogoLoader />}
            {!isCheckout && <h1 className="mb-7.5 mt-5 text-3.5xl font-bold">{$t("Log in / Register")}</h1>}

            <form className="mb-7.5">
                <div className="mb-7.5 flex flex-col items-center lg:flex-row">
                    <div className="flex w-full basis-full items-center lg:basis-2/12">
                        <label htmlFor="username">{$t("E-mail address")} *</label>
                    </div>

                    <div className={classNames("w-full basis-full", {
            "lg:basis-7/12": !isCheckout,
            "lg:basis-10/12": isCheckout,
        })}>
                        <div className="flex items-center bg-gray-bg">
                            <Input className="w-full" id="username" name="username" type="email" value={emailForCheck} disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className="mb-7.5 flex flex-col items-center lg:flex-row">
                    <div className="flex w-full shrink-0 basis-full items-center lg:basis-2/12">
                        <label htmlFor="password">{$t("Password")} *</label>
                    </div>
                    <div className={classNames("w-full basis-full", {
            "lg:basis-7/12": !isCheckout,
            "lg:basis-10/12": isCheckout,
        })}>
                        <div className="flex items-center">
                            <div className="flex w-full items-center">
                                <Input className="w-full" id="password" name="password" type={isPasswordShown ? "text" : "password"} onInput={onPasswordFieldChange} autoComplete="current-password"/>
                            </div>
                            <div className="flex h-[46px] w-[35px] cursor-pointer items-center justify-center rounded-r-sm rounded-b-sm border border-gray-light hover:border-gray-shuttle" onClick={togglePassword}>
                                <NextImage blur={false} src={isPasswordShown ? "/svgAsComponent/eyes-slash.svg" : "/svgAsComponent/eyes.svg"} alt="pwd icon" width="15" height="15"/>
                            </div>
                        </div>

                        {isPasswordInvalid && <div className="password-error">{$t("Invalid password")}</div>}
                    </div>
                </div>
                <div className="flex">
                    <div className={classNames("flex w-full lg:justify-end", isCheckout ? "justify-end lg:basis-full" : "basis-full justify-end lg:basis-9/12")}>
                        <Button className="py-2.75 px-7.5 hover:!bg-orange-main" bgColor="#809A7B" color="#fff" disabled={isLoginBtnDisabled || isLoginIng} onClick={handleLoginUser}>
                            {$t("next one")}
                        </Button>
                    </div>
                </div>
            </form>

            <div className="mb-2.75" onClick={toggleModal}>
                <ArrowText>{$t("Forgot your password?")}</ArrowText>
            </div>
            <div>
                <span onClick={goToStepOne} className="inline-block">
                    <ArrowText className="mb-2.5">{$t("Log in with a different account")}</ArrowText>
                </span>
            </div>
        </div>);
};
export default LoginForm;
