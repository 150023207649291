import { AddedItem } from "@/components/CartPopup/AddedItem";
import { useStores } from "@/stores";
import { AddedProductPopupAnimation, Link, useAddedProduct, useI18n } from "@jog/react-components";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useMemo } from "react";
export const AddedProductPopup = observer(({ className }) => {
    const { skus } = useAddedProduct();
    const { cartStore } = useStores();
    const lineItems = useMemo(() => { var _a; return ((_a = cartStore.cartLineItems) === null || _a === void 0 ? void 0 : _a.filter((item) => skus.includes(item.sku))) || []; }, [cartStore.cartLineItems, skus]);
    const { $t } = useI18n();
    return (<AddedProductPopupAnimation className={className}>
            <div className={classNames("w-[280px] border-gray-main bg-white py-4 px-3 shadow", {
            hidden: lineItems.length === 0,
        })}>
                <h3 className="text-sm font-medium uppercase leading-tight tracking-0.03 text-grey-default">
                    {$t("Added to cart")}
                </h3>
                <ul className="no-scrollbar mb-6 max-h-[440px] overflow-y-auto">
                    {lineItems === null || lineItems === void 0 ? void 0 : lineItems.map((item) => <AddedItem key={item.id} item={item}/>)}
                </ul>
                <Link href="/cart" className="flex w-full justify-center bg-green-main py-3 text-sm font-medium uppercase tracking-0.03 text-white hover:bg-grey-default">
                    {$t("To cart")}
                </Link>
            </div>
        </AddedProductPopupAnimation>);
});
